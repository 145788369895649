import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import {
  featuredWrapper,
  noFeaturedWrapper,
  wrapper1200,
  color,
  breakpoints,
  font,
  ContentStyles,
  ContentStylesExt,
  wrapper,
} from '../../utils/style';
import ButtonLink from '../../components/shared/ButtonLink';
import ContentWithFluidImages from '../../components/shared/ContentWithFluidImages';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import GoodStars from '../../components/Content/Providers/GoodStars';
import BadStars from '../../components/Content/Providers/BadStars';
import Bell from '../../icons/svg/Bell';

const FeaturedWrapper = styled('div')`
  ${featuredWrapper}
  position: relative;
  margin: 0;

  .info-wrapper {
    position: absolute;
    left: 0;
    bottom: 10%;
    z-index: 1;
    margin: 0 6%;
  }
  .button {
    margin-right: 40px;
  }
  .rating-block-banner {
    display: inline-block;
    vertical-align: top;
    padding: 0 40px;
    border-left: 1px solid ${color.transparentWhite2};
    height: 50px;
  }
  .rating-title {
    display: block;
    margin-bottom: 5px;
  }
  .rating-stars {
    .good {
      color: ${color.yellow};
    }
    .star {
      margin-right: 2px;
    }
  }
  .gatsby-image-wrapper {
    border-radius: 24px;
  }
  @media (max-width: ${breakpoints.md}) {
    .info-wrapper {
      position: static;
      margin: 10px 0 20px;
    }
    .gatsby-image-wrapper {
      border-radius: 10px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .button {
      margin: 0 0 10px 0;
    }
    .rating-block-banner {
      display: block;
      padding-left: 0;
      height: auto;
      border-left: none;
    }
    .rating-title {
      display: inline;
      margin-right: 15px;
    }
    .gatsby-image-wrapper {
      border-radius: 14px;
    }
  }
`;

const NoFeaturedWrapper = styled('div')`
  ${noFeaturedWrapper}
  margin: 35px 0;
`;

const WrapperDesktop = styled('div')`
  ${wrapper1200}
`;

const ContentWrapper = styled('div')`
  ${wrapper}
  ${ContentStyles}
  ${ContentStylesExt}
`;

const Bg = styled('div')`
  border-top: 1px solid ${color.transparentWhite1};
  padding: 15px 0 5px;
`;
const RelatedWrapper = styled('div')`
  padding: 30px 0;
  margin-top: 10px;
  text-align: center;
  h3 {
    font-size: 1.6rem;
    margin-top: 0;
  }
  ul {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  li {
    width: 23%;
    text-align: left;
  }

  .recent-name {
    font-size: 1rem;
    font-weight: ${font.headingWeight};
    margin: 20px 0 10px;
  }

  .recent-excerpt {
    font-size: 14px;
    line-height: 20px;
    max-height: 140px;
    overflow-y: hidden;
  }

  @media (max-width: ${breakpoints.md}) {
    ul {
      margin: 10px 0;
    }
    li {
      width: 48%;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    li {
      width: 100%;
    }
  }
`;

const PageTemplate = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage;
  const featuredMedia = currentPage.featured_media;
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : currentPage.title + '- ' + process.env.OWNER;
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : '';
  const relatedPosts = data.allWordpressPost;
  const placeholder = data.placeholder;
  const relatedIds = pageContext.related;
  const media = data.allWordpressWpMedia.edges;
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;
  const providerRating = +currentPage.acf.provider_rating;
  const providerRatingMissing = 5 - providerRating;
  const socialImage = currentPage.featured_media?.localFile?.publicURL;

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        rating={providerRating}
        gameTitle={page_title}
        socialImage={socialImage}
      />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        {featuredMedia &&
        featuredMedia.localFile &&
        featuredMedia.localFile.childImageSharp &&
        featuredMedia.localFile.childImageSharp.fluid ? (
          <FeaturedWrapper>
            <Img
              fluid={featuredMedia.localFile.childImageSharp.fluid}
              alt={
                currentPage.featured_media.alt_text
                  ? currentPage.featured_media.alt_text
                  : currentPage.title
              }
            />
            {((currentPage.featured_media &&
              currentPage.featured_media.acf &&
              currentPage.featured_media.acf.slide_url) ||
              (currentPage.acf && currentPage.acf.provider_rating)) && (
              <div className="info-wrapper">
                {currentPage.featured_media &&
                  currentPage.featured_media.acf &&
                  currentPage.featured_media.acf.slide_url && (
                    <ButtonLink
                      to={currentPage.featured_media.acf.slide_url}
                      className="button red-button"
                      iconName="play"
                      text="อเริ่มเล่น"
                      external={true}
                    />
                  )}
                {currentPage.acf.provider_rating && (
                  <div className="rating-block-banner">
                    <span className="rating-title">อันดับ</span>
                    <span className="rating-stars">
                      <GoodStars n={providerRating} cssClass="good" />
                      {providerRatingMissing > 0 && (
                        <BadStars n={providerRatingMissing} cssClass="bad" />
                      )}
                    </span>
                  </div>
                )}
              </div>
            )}
          </FeaturedWrapper>
        ) : (
          <NoFeaturedWrapper>
            <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
          </NoFeaturedWrapper>
        )}

        <ContentWrapper>
          {featuredMedia &&
            featuredMedia.localFile &&
            featuredMedia.localFile.childImageSharp &&
            featuredMedia.localFile.childImageSharp.fluid && (
              <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
            )}
          {process.env.WP_REPLACE_CONTENT_IMAGES === '1' ? (
            ContentWithFluidImages(currentPage.content, media)
          ) : (
            <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
          )}
        </ContentWrapper>

        {relatedIds.length > 0 && (
          <Bg>
            <RelatedWrapper>
              <h3 className="h3 with-icon">
                <Bell />
                <span>อัพเดท บทความ ข่าวสารจาก GClub</span>
              </h3>
              <ul>
                {relatedPosts.edges.map((item, index) => (
                  <li key={item.node.slug + index}>
                    <Link to={'/' + item.node.slug + '/'}>
                      {item.node.featured_media &&
                      item.node.featured_media.localFile &&
                      item.node.featured_media.localFile.childImageSharp &&
                      item.node.featured_media.alt_text ? (
                        <div className="img-wrap">
                          <Img
                            fluid={
                              item.node.featured_media.localFile.childImageSharp
                                .fluid
                            }
                            alt={item.node.featured_media.alt_text}
                          />
                        </div>
                      ) : (
                        <div className="img-wrap">
                          <Img
                            fluid={placeholder.childImageSharp.fluid}
                            alt={item.node.label}
                          />
                        </div>
                      )}
                      <div
                        className="recent-name"
                        dangerouslySetInnerHTML={{ __html: item.node.title }}
                      />
                      <div
                        className="recent-excerpt"
                        dangerouslySetInnerHTML={{ __html: item.node.excerpt }}
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </RelatedWrapper>
          </Bg>
        )}
      </WrapperDesktop>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!, $related: [Int], $contentImages: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      comment_status
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
        provider_established_date
        provider_games
        provider_language
        provider_currency
        provider_rating
      }
      featured_media {
        alt_text
        acf {
          slide_url
        }
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allWordpressPost(
      filter: {
        wordpress_id: { in: $related }
        acf: { post_template: { ne: "service_post_carousel" } }
      }
      limit: 4
    ) {
      edges {
        node {
          slug
          title
          excerpt
          wordpress_id
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allWordpressWpMedia(filter: { wordpress_id: { in: $contentImages } }) {
      edges {
        node {
          wordpress_id
          alt_text
          source_url
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
              }
            }
          }
        }
      }
    }
  }
`;
